:root {
    --pagination-color: white;
    --pagination-hover-color: #424242;
    --pagination-bg: #424242;
    --pagination-border-color: #424242;
    --pagination-active-bg: white;
    --pagination-active-color: #424242;
}

.center-element {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}