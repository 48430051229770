.match-list-item-label-text:hover {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.add-point:hover {
  cursor: pointer;
}

.delete-point {
  margin-right: 0px !important;
}