.columnpadding {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.fixTableHead {
    height: 300px;
}

.fixTableHead thead th {
    position: sticky;
    top: 0;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th {
    background: rgba(6, 19, 37, 0.96);
}