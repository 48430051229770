.addplayer {
  margin-right: 3rem;
  margin-left: auto;
  display: flex;
  gap: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.playerGroups {
  padding: 10px;
  margin-left: 120px;
  width: 50%;
}
.players {
  display: flex;
  gap: 5px;
}
.actions{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;

}

@media (max-width: 576px) {
  .addplayer {
    margin-top: 2rem;
    margin-right: 0;
  }
  .playerGroups {
    margin-left: 200px;
  }
  .players {
    flex-direction: column;
  }

  .playerGroups .players .name {
    flex-direction: row;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 100px;
    }
}
