.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.form-group {
    display: inline-grid;
    gap: 5px;
    flex: 1;
}

input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.radio-group {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex: 1;

    label {
        font-size: 16px;

        input {
            width: 20px;
            height: 20px;
        }
    }
}

.AObutton {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
}

.AObutton:hover {
    background-color: #0056b3;
}

/* table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 100px;
}

th,
td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
} */