.action-button {
    background-color: transparent;
    border-color: white;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
}


.action-button-enabled:hover {
    color: #343a40;
    background-color: #f8f9fa;
    cursor: pointer;
}

.delete-button {
    background-color: transparent;
    border-color: red;
    color: red;
}

.delete-button-enabled:hover {
    color: #f8f9fa;
    background-color: red;
    cursor: pointer;
}